@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-angular-documenteditor/styles/material.css';

@import '~@syncfusion/ej2-pdfviewer/styles/material.css';
@import '~@syncfusion/ej2-notifications/styles/material.css';

//@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';

@import '~@syncfusion/ej2-calendars/styles/material.css';
@import '~@syncfusion/ej2-angular-schedule/styles/material.css';


.e-schedule {
    .e-work-cells.e-current-date, .e-header-cells.e-current-day.e-date-header {
        $color: blue;
        background: rgba(red($color),green($color),blue($color), 0.1);
    }
    .e-schedule-table.e-content-table {
        background: white;
    }
}
.e-schedule.hide-left-row .e-schedule-table.e-outer-table > tbody > tr > td:first-child {
    width: 0;
}
